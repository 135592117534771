import { useLanguage } from '../../contexts/LanguageContext';
import "./functions2.css";
import billetes from '../../assets2/Funtions/economico.png'
import candado from '../../assets2/Funtions/seguridad.png'
import control from '../../assets2/Funtions/controlar.png'
import llave from '../../assets2/Funtions/llaves.png'
import pantallas from '../../assets2/Funtions/dispositivos.png'
import reciclar from '../../assets2/Funtions/escalable.png'

import logo from '../../assets2/pinlet-completo.png'
import initImg from '../../assets2/Funtions/img_init.webp'
import { Link } from 'react-router-dom';

import { ResidentsSection } from "../ResidentsSection/residentsSection";
import { Extra } from "../Extra/extra"
import { ClientsSection } from "../ClientsSection/clientsSection";
import { EasyUse } from "../EasyUse/easyUse";
import { Init } from "../InitSection/initSection";

export const Functions2 = () => {

  const { language, translations } = useLanguage();

  return (
    <div className="main_functions2">
      <div className='main_contenedor'>

        <div className='info'>
          <div className='text_info'>
            <img className='logo' src={logo} />
            <h1>{translations[language].home.inicio.titulo.toUpperCase()}</h1>
            <h4>{translations[language].home.inicio.subtitulo[1]}
              {translations[language].home.inicio.subtitulo[2]}
              {translations[language].home.inicio.subtitulo[3]}
              {translations[language].home.inicio.subtitulo[4]}</h4>
            <div className='btns_info'>
              <Link to="/descargar"> {translations[language].home.inicio.btn.descarga}</Link>
              <Link to="/contacts"> {translations[language].home.inicio.btn.contactanos}</Link>
            </div>
          </div>
          <div className='img_info'>
            <img src={initImg} />
          </div>
        </div>

        <div className='detalles'>
          <div className='punto'>
            <img src={candado} />
            <h2>{translations[language].home.inicio.seguro}</h2>
          </div>
          <div className='punto'>
            <img src={control} />
            <h2>{translations[language].home.inicio.control}</h2>
          </div>
          <div className='punto'>
            <img src={llave} />
            <h2>{translations[language].home.inicio.acceso}</h2>
          </div>
          <div className='punto'>
            <img src={pantallas} />
            <h2>{translations[language].home.inicio.variedad}</h2>
          </div>
          <div className='punto'>
            <img src={billetes} />
            <h2>{translations[language].home.inicio.costos}</h2>
          </div>
          <div className='punto'>
            <img src={reciclar} />
            <h2>{translations[language].home.inicio.sostenible}</h2>
          </div>
        </div>
      </div>
      <Init />

      <div id="funcionalidades"></div>
      {/*<WhenToUse/>*/}
      {/*<EasyToUseSection/>*/}

      <EasyUse />
      <ResidentsSection />

      {/*<ReviewsSection/>*/}
      <Extra />
      <ClientsSection />
    </div>

  )
}